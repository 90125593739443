<template>
  <b-modal
      id="modal-1"
      v-model="visibleModal"
      title="Aktywacja 2FA"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <img :src="qrCodeUrl" />
    </div>

    <div class="form-group text-center">
      <label>Kod:</label>
      <input v-model="form.otpCode" class="form-control" :class="{ 'is-invalid': submitted && $v.form.otpCode.$error }" />
      <div v-if="!$v.form.otpCode.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="text-right">
      <b-button @click="enable2FA" variant="success">Włącz 2FA</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {userService} from "@/helpers/user.service";
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";

export default {
  name: "enable-2fa-modal",

  data() {
    return {
      submitted: false,
      visibleModal: false,
      qrCodeUrl: "",

      form: {
        otpCode: ""
      }
    }
  },

  validations: {
    form: {
      otpCode: { required }
    }
  },

  methods: {
    async openModal() {
      await this.loadQrCodeUrl()
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.qrCodeUrl = ""
      this.form.otpCode = ""
    },

    async loadQrCodeUrl() {
      try {
        const { data } = await axios.post(`/user/2fa/setup`, {
          data: {},
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
        })

        this.qrCodeUrl = data.qrCodeUrl
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    enable2FA() {
      this.submitted = true

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.form)

      axios.post(`/user/2fa/enable`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        userService.login(false).then(() => {
          this.hideModal()
          Swal.fire("Sukces!", "Logowanie dwuetapowe zostało włączone!", "success");
          this.$emit("enabled-2fa")
        })
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })

      this.submitted = false
    }
  }

}
</script>