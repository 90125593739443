<template>
  <b-modal
      id="modal-1"
      v-model="visibleModal"
      title="Obrabianie zdjęcia"
      :no-close-on-backdrop="true"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">

    <cropper
        ref="cropper"
        class="cropper"
        :src="image"
        :stencil-props="{aspectRatio: aspectRatio}"
        :stencil-component="stencilComponent"
    />

    <div class="text-right py-2">
      <b-button variant="success" @click="save">Zapisz</b-button>
      <b-button class="ml-1" variant="secondary" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      visibleModal: false,
      image: "",
      extension: "",
      stencilComponent: "",
      aspectRatio: 10/12
    }
  },

  methods: {
    openModal(image, stencilComponent = "rectangle-stencil", aspectRatio = 10/12) {
      this.image = image
      this.extension = image.split(';')[0].split('/')[1];
      this.stencilComponent = stencilComponent
      this.aspectRatio = aspectRatio
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.image = ""
      this.extension = ""
    },

    save() {
      const {canvas} = this.$refs.cropper.getResult();
      canvas.toBlob(blob => {
        const file = new File([blob], `file.${this.extension}`, { type: `image/${this.extension}` })
        this.$emit("success", {
          dataUrl: canvas.toDataURL(),
          file: file,
          extension: this.extension
        })

        this.hideModal()
      }, `image/${this.extension}`);
    }
  }
}
</script>